<template>
  <span style="word-break: break-all;">
    <a href="#" @click.prevent="download()" v-if="url==null">
      {{(!isOriginal && fileType === 'Report' ? 'Site Visit Report' : filename)}}
      <span v-if="isLoading">
        <b-spinner label="Loading..." variant="primary" small />
      </span>
      <span v-else>
        <font-awesome-icon :icon="downloadIcon" />
      </span>
    </a>
    <a v-else :href="url" target="_blank">
      {{(!isOriginal && fileType === 'Report' ? 'Site Visit Report' : filename)}} &nbsp;
      <span>
        <font-awesome-icon :icon="fileIcon" />
        <small class="text-muted">Open File</small>
      </span>
    </a>
  </span>
</template>

<script>
  import apiRequest from '../../JS/api-request';
  import { faSpinner, faCloudDownloadAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
  import { BSpinner } from 'bootstrap-vue';

  export default {
    name: 'FileDownload',
    components: { BSpinner },
    data: function () {
      return {
        isLoading: false,
        url: null
      };
    },
    props: ['filename', 'customerId', 'isOriginal', 'isGlobal', 'fileType'],
    computed: {
      spinnerIcon() {
        return faSpinner;
      },
      downloadIcon() {
        return faCloudDownloadAlt;
      },
      fileIcon() {
        return faFileAlt;
      }
    },
    methods: {
      async download() {
        var url = this.isGlobal ? `/api/globalfiles/${this.filename}` : `/api/customers/${this.customerId}/files/${this.filename}?isOriginal=${this.isOriginal}`;

        await apiRequest({
          method: 'get',
          url,
          busyFunc: x => this.isLoading = x,
          successFunc: x => {
            this.url = x;
          },
          errorMsg: 'There was an fetching download link'
        });
      }
    }
  }
</script>
<style scoped>
  a:hover {
    text-decoration: none;
  }
</style>
