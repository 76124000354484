<template>
  <tr>
    <td style="width: 100px;" class="align-middle">
      <small class="text-muted text-nowrap">{{file.visitDate | formatDate}}</small>
    </td>
    <td class="align-middle">
      <font-awesome-icon :icon="emailIcon" v-if="file.sentEmail" />
      <a href="#" @click.prevent="viewReport(file)" v-if="file.fileType === 'Report' && !file.isOriginal">
        Site Visit Report
        <font-awesome-icon :icon="viewIcon" />
      </a>
      <file-download v-else :filename="file.name" :is-original="file.isOriginal" :file-type="file.fileType" :customer-id="customerId"></file-download>
      <span v-if="showHistory">
        <font-awesome-icon :icon="infoIcon" :id="`history-${file.id}`" />
        <b-tooltip :target="`history-${file.id}`" title="History" triggers="hover" @show="getHistory">
          <b-spinner v-if="isLoading" label="Loading..." variant="primary" size="small"></b-spinner>
          <ul v-else-if="history.length > 0">
            <li v-for="item in history">
              {{item.auditDate | formatDate}} - {{item.user}} ({{item.action}})
            </li>
          </ul>
          <span v-else>No History</span>
        </b-tooltip>
      </span>
    </td>
    <td style="width: 50px;" class="align-middle" v-if="showDelete">
      <a href="#" class="btn btn-link text-danger" @click.prevent="$emit('delete-file', file)"><font-awesome-icon :icon="deleteIcon" /></a>
    </td>
  </tr>
</template>

<script>
  import FileDownload from './FileDownload.vue';
  import { BTooltip, BSpinner } from 'bootstrap-vue';
  import { format, parseISO } from 'date-fns';
  import { faEnvelope, faEye } from '@fortawesome/free-regular-svg-icons';
  import { faTrash, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
  import apiRequest from '../../JS/api-request';

  export default {
    name: 'FileItem',
    components: { FileDownload, BTooltip, BSpinner },
    props: ['file', 'customerId', "showDelete", "showHistory"],
    filters: {
      formatDate(value) {
        if (!value) return '';
        return format(parseISO(value), 'dd MMM yyyy');
      }
    },
    data: function () {
      return {
        history: [],
        isLoading: false
      };
    },
    computed: {
      emailIcon() {
        return faEnvelope;
      },
      deleteIcon() {
        return faTrash;
      },
      viewIcon() {
        return faEye;
      },
      infoIcon() {
        return faCircleInfo;
      }
    },
    methods: {
      viewReport(file) {
        window.open(`/print/report?customerid=${this.customerId}&filename=${file.name}`);
      },
      getHistory() {
        apiRequest({
          method: 'get',
          url: `/api/customers/${this.customerId}/files/${this.file.id}/history`,
          busyFunc: x => this.isLoading = x,
          successFunc: x => {
            this.history = x;
          },
          errorMsg: 'There was an error fetching contracts',
          toaster: this.$bvToast
        });
      }
    }
  }
</script>
