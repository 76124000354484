var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "form-inline my-2",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "input-group mb-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedFileType,
                    expression: "selectedFileType",
                  },
                ],
                staticClass: "custom-select",
                attrs: { id: "inputGroupSelect01" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedFileType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.fileTypes, function (fileType) {
                return _c("option", { domProps: { value: fileType.id } }, [
                  _vm._v(_vm._s(fileType.name)),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "input-group mb-3 ml-md-2" }, [
            _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "span",
                {
                  staticClass: "input-group-text",
                  attrs: { id: "basic-addon1" },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: _vm.searchIcon },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              staticClass: "form-control mr-sm-2",
              attrs: {
                type: "search",
                placeholder: "Search",
                "aria-label": "Search",
              },
              domProps: { value: _vm.search },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.search = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("b-skeleton-table", {
            attrs: {
              rows: 5,
              columns: 4,
              "table-props": { bordered: true, striped: true },
            },
          })
        : _c("table", { staticClass: "table table-striped filelist" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.orderColumn = "visitDate"
                        _vm.orderUp = !_vm.orderUp
                      },
                    },
                  },
                  [
                    _vm.orderColumn == "visitDate"
                      ? _c("font-awesome-icon", {
                          attrs: { icon: _vm.orderIcon },
                        })
                      : _vm._e(),
                    _vm._v(" Date"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.orderColumn = "name"
                        _vm.orderUp = !_vm.orderUp
                      },
                    },
                  },
                  [
                    _vm.orderColumn == "name"
                      ? _c("font-awesome-icon", {
                          attrs: { icon: _vm.orderIcon },
                        })
                      : _vm._e(),
                    _vm._v(" Name"),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showDelete ? _c("th") : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.sortedFiles, function (file) {
                  return _c("file-item", {
                    key: file.id,
                    attrs: {
                      file: file,
                      "customer-id": _vm.customerId,
                      "show-delete": _vm.showDelete,
                      "show-history": _vm.showHistory,
                    },
                    on: {
                      "delete-file": function ($event) {
                        return _vm.deleteFile($event)
                      },
                    },
                  })
                }),
                _vm._v(" "),
                _vm.sortedFiles.length == 0
                  ? _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text-muted", attrs: { colspan: "2" } },
                        [_vm._v("No Files Available.")]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          attrs: { for: "inputGroupSelect01" },
        },
        [_vm._v("Type")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }