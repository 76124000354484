<template>
  <form enctype="multipart/form-data" @submit.prevent="submitFile" id="upload-form">
    <div class="form-group">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Type</label>
        </div>
        <select v-model="selectedFileType" class="custom-select" id="inputGroupSelect01">
          <option v-for="fileType in fileTypes" :value="fileType.id">{{fileType.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group" v-if="selectedFileType!=='Report'">
      <div class="custom-file">
        <input type="file"
               class="custom-file-input cursor-pointer"
               name="uploadFile"
               id="dataFileInput"
               @change="fileInputChange($event.target.files)" />
        <label class="custom-file-label" for="dataFileInput" v-if="fileName != null">{{fileName}}</label>
        <label class="custom-file-label" for="dataFileInput" v-if="fileName == null">Choose a file</label>
      </div>
    </div>
    <div v-if="selectedFileType==='Report'">
      <div class="form-group">
        <label for="findings">Report Date</label>
        <vue-date-picker placeholder="Choose date" v-model="reportDate" required />
      </div><div class="form-group">
        <label for="findings">Time on site (Minutes)</label>
        <input v-model="timeOnSite" type="number" class="form-control" />
      </div><div class="form-group">
        <label for="findings">Revisit Date</label>
        <vue-date-picker placeholder="Choose date" v-model="revisitDate" required />
      </div>
      <activity-select v-model="fileActivities" :customer-id="customerId" :site-id="siteId" />
      <div class="form-group">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group id="btn-radios-2"
                              v-model="visitType"
                              :options="reportOptions"
                              :aria-describedby="ariaDescribedby"
                              button-variant="outline-primary"
                              name="radio-btn-outline"
                              buttons></b-form-radio-group>
        </b-form-group>
      </div><div class="form-group">
        <label for="isRodenticideUsed">Will Rodenticide be used?</label>
        <input type="checkbox" id="isRodenticideUsed" v-model="isRodenticideUsed" class="ml-2" />
      </div><div class="form-group">
        <label for="rodenticideUsed">Rodenticide used</label>
        <select v-model="rodenticideUsed" class="custom-select mb-2" id="rodenticideUsed">
          <option v-for="rodenticideType in reportData['rodenticideTypes']" :value="rodenticideType.value">{{rodenticideType.key}}</option>
        </select>
        <select v-model="rodenticideUsed2" class="custom-select" id="rodenticideUsed2">
          <option v-for="rodenticideType in reportData['rodenticideTypes']" :value="rodenticideType.value">{{rodenticideType.key}}</option>
        </select>
      </div><div class="form-group">
        <label for="infestationType">Infestation Type</label>
        <select v-model="infestationType" class="custom-select" id="infestationType">
          <option v-for="infType in infTypes" :value="infType">{{infType}}</option>
        </select>
      </div><div class="form-group">
        <label for="population">Population</label>
        <select v-model="population" class="custom-select" id="population">
          <option v-for="popType in popTypes" :value="popType">{{popType}}</option>
        </select>
      </div><div class="form-group">
        <label for="isInsideOnly">Bait Indoors Only</label>
        <input type="checkbox" id="isInsideOnly" v-model="isInsideOnly" class="ml-2" />
      </div><div v-if="!isInsideOnly">
        <div class="form-group">
          <label for="otherMethods">Why can't other methods be used</label>
          <select v-model="otherSelect" class="custom-select mb-2" id="otherSelect" @change="otherMethods = otherSelect">
            <option v-for="otherType in reportData['otherTypes']" :value="otherType.value">{{otherType.key}}</option>
          </select>
          <textarea class="form-control" v-model="otherMethods" id="otherMethods" rows="3" :rows="rows(otherMethods)"></textarea>
        </div><div class="form-group">
          <label for="fgars">Why is it intended to use FGARs/SGARs?</label>
          <select v-model="fgarSelect" class="custom-select mb-2" id="fgarSelect" @change="fgars = fgarSelect">
            <option v-for="fgarType in reportData['fgarTypes']" :value="fgarType.value">{{fgarType.key}}</option>
          </select>
          <textarea class="form-control" v-model="fgars" id="fgars" rows="3" :rows="rows(fgars)"></textarea>
        </div><div class="form-group">
          <label for="anyChanges">Any changes to the initial ERA?</label>
          <input type="checkbox" id="anyChanges" v-model="anyChanges" class="ml-2" />
        </div>
      </div><div class="form-group">
        <label for="findings">Findings and Treatments</label>
        <textarea class="form-control" v-model="findings" id="findings" :rows="rows(findings)" required></textarea>
      </div><div class="form-group">
        <label for="recommendations">Recommendations</label>
        <textarea class="form-control" v-model="recommendations" :rows="rows(recommendations)" id="recommendations" required></textarea>
      </div><div class="form-group">
        <label for="sendEmail">Send Email</label>
        <input type="checkbox" id="sendEmail" v-model="sendEmail" class="ml-2" />
      </div><div class="form-group">
        <label for="sendEmail">Send Email to Main Contact</label>
        <input type="checkbox" id="sendEmailMain" v-model="sendEmailMain" class="ml-2" />
      </div><div class="form-group" v-if="contacts && contacts.length > 0">
        <label for="email">Report contact override</label>
        <select v-model="contactId" class="custom-select" id="inputGroupSelect01">
          <option v-for="contact in contacts" :value="contact.id">{{contact.name}}</option>
        </select>
      </div>
    </div><div class="form-group">
      <button type="submit" class="btn btn-primary" :disabled="isUploading"><b-spinner label="Loading..." variant="light" small v-if="isUploading" /> Upload</button>
    </div>
  </form>
</template>

<script>
  import apiRequest from '../../JS/api-request';
  import { BSpinner, BFormGroup, BFormRadioGroup } from 'bootstrap-vue';
  import ActivitySelect from './ActivitySelect.vue';

  export default {
    name: 'FileUpload',
    props: ["customerId", "siteId"],
    components: { BSpinner, BFormGroup, BFormRadioGroup, ActivitySelect },
    data() {
      return {
        infTypes: ["Mice", "Rats", "Mice & Rats"],
        popTypes: ["Single rodent or small family in single area", "Small to medium colony in particular area/s on site", "Large colony over numerous areas on site"],
        reportOptions: [
          { text: 'Routine', value: 'routine' },
          { text: 'New', value: 'New' },
          { text: 'Follow Up', value: 'followup' }
        ],
        reportData: { 'rodenticideTypes': [], 'otherTypes': [], 'fgarTypes': [] },
        otherSelect: "",
        fgarSelect: "",
        fileName: null,
        currentStatus: null,
        formData: null,
        errors: [],
        isUploading: false,
        reportDate: null,
        revisitDate: null,
        findings: "",
        recommendations: "",
        visitType: "routine",
        isReport: true,
        fileTypes: [],
        selectedFileType: 'report',
        options: {
          format: 'DD/MM/YYYY',
          useCurrent: false
        },
        uploadFile: null,
        sendEmail: false,
        sendEmailMain: false,
        contactId: null,
        contacts: null,
        infestationType: "",
        population: "",
        fgars: "",
        otherMethods: "",
        rodenticideUsed: "",
        rodenticideUsed2: "",
        anyChanges: false,
        isRodenticideUsed: false,
        isInsideOnly: true,
        timeOnSite: 0,
        fileActivities: []
      };
    },
    mounted() {
      this.load();
      this.resetForm();
    },
    watch: {
      visitType(val) {
        if (val === 'followup') {
          this.lastERA();
        }
      }
    },
    methods: {
      rows(value) {
        const chars =  38;
        return value ? value.split(/\r*\n/).reduce((r, x) => r + Math.ceil(x.length / chars), 1) : 2;
      },
      async load() {
        console.log('here');
        await Promise.all([apiRequest({
          method: 'get',
          url: '/api/accountcontacts',
          busyFunc: x => x,
          successFunc: x => {
            this.contacts = x
          },
          errorMsg: 'There was an error loading contacts',
          toaster: this.$bvToast
        }),
        apiRequest({
          method: 'get',
          url: '/api/filetypes',
          busyFunc: x => x,
          successFunc: x => {
            this.fileTypes = x
          },
          errorMsg: 'There was an error loading filetypes',
          toaster: this.$bvToast
        }),
          apiRequest({
            method: 'get',
            url: '/api/config/report',
            busyFunc: x => x,
            successFunc: x => {
              this.reportData = x
            },
            errorMsg: 'There was an error loading report data',
            toaster: this.$bvToast
          })]);
      },
      resetForm() {
        this.otherSelect = "";
        this.fgarSelect = "";
        this.fileName = null;
        this.formData = null;
        this.reportDate = null;
        this.revisitDate = null;
        this.uploadFile = null;
        this.selectedFileType = "Report";
        this.findings = "";
        this.recommendations = "";
        this.visitType = "routine";
        this.sendEmail = false;
        this.sendEmailMain = false;
        this.contactId = null;
        this.infestationType = "";
        this.population = "";
        this.fgars = "";
        this.otherMethods = "";
        this.rodenticideUsed = "";
        this.rodenticideUsed2 = "";
        this.anyChanges = false;
        this.isRodenticideUsed = false;
        this.isInsideOnly = true;
        this.timeOnSite = 0;
      },
      fileInputChange(fileList) {
        if (fileList.length === 0) {
          this.fileName = null;
          this.uploadFile = null;
          return;
        }

        this.uploadFile = fileList[0];
        this.fileName = fileList[0].name;
      },
      async submitFile() {
        this.errors = [];
        this.isUploading = true;

        this.formData = new FormData();
        if (this.reportDate) {
          this.formData.append("reportDate", this.reportDate);
        }
        if (this.revisitDate) {
          this.formData.append("revisitDate", this.revisitDate);
        }
        if (this.uploadFile) {
          this.formData.append("uploadFile", this.uploadFile, this.uploadFile.name);
        }

        this.formData.append("fileType", this.selectedFileType);
        this.formData.append("findings", this.findings);
        this.formData.append("recommendations", this.recommendations);
        this.formData.append("visitType", this.visitType);
        this.formData.append("sendEmail", this.sendEmail);
        this.formData.append("sendEmailMain", this.sendEmailMain);
        this.formData.append("infestationType", this.infestationType);
        this.formData.append("population", this.population);
        this.formData.append("fgars", this.fgars);
        this.formData.append("otherMethods", this.otherMethods);
        this.formData.append("rodenticideUsed", this.rodenticideUsed);
        this.formData.append("rodenticideUsed2", this.rodenticideUsed2);
        this.formData.append("anyChanges", this.anyChanges);
        this.formData.append("isRodenticideUsed", this.isRodenticideUsed);
        this.formData.append("isInsideOnly", this.isInsideOnly);
        this.formData.append("timeOnSite", this.timeOnSite);
        if (this.fileActivities) {
          for (var i = 0; i < this.fileActivities.length; i++) {
            this.formData.append('fileActivityIDs[]', this.fileActivities[i]);
          }
        }
        if (this.contactId) {
          this.formData.append("contactId", this.contactId);
        }

        await apiRequest({
          method: 'post',
          url: `/api/customers/${this.customerId}/sites/${this.siteId}/files`,
          data: this.formData,
          busyFunc: x => this.isUploading = x,
          successFunc: x => {
            this.resetForm();
            this.$emit('close');
            this.$root.$emit('file-uploaded');
          },
          errorMsg: 'There was an error uploading file',
          successMsg: 'Report Added',
          toaster: this.$bvToast
        });
      },
      async lastERA() {
        await apiRequest({
          method: 'get',
          url: `/api/customers/${this.customerId}/sites/${this.siteId}/files/lastera`,
          data: this.formData,
          busyFunc: x => this.isUploading = x,
          successFunc: x => {
            var era = "See initial report - " + x;
            this.fgars = era;
            this.otherMethods = era;
          },
          errorMsg: 'There was an error fetching era',
          toaster: this.$bvToast
        });
      }
    }
  }
</script>
