<template>
  <div>
    <form class="form-inline my-2" @submit.prevent>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Type</label>
        </div>
        <select v-model="selectedFileType" class="custom-select" id="inputGroupSelect01"><option v-for="fileType in fileTypes" :value="fileType.id">{{fileType.name}}</option></select>
      </div><div class="input-group mb-3 ml-md-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><font-awesome-icon :icon="searchIcon" class="mr-2" /></span>
        </div>
        <input class="form-control mr-sm-2" v-model="search" type="search" placeholder="Search" aria-label="Search">
      </div>
    </form>
    <b-skeleton-table :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }" v-if="isLoading" />
    <table v-else class="table table-striped filelist">
      <thead>
        <tr>
          <th @click="orderColumn = 'visitDate'; orderUp = !orderUp" class="cursor-pointer"><font-awesome-icon :icon="orderIcon" v-if="orderColumn=='visitDate'" /> Date</th>
          <th @click="orderColumn = 'name'; orderUp = !orderUp" class="cursor-pointer"><font-awesome-icon :icon="orderIcon" v-if="orderColumn=='name'" /> Name</th>
          <th v-if="showDelete"></th>
        </tr>
      </thead>
      <tbody>
        <file-item v-for="file in sortedFiles" :file="file" :key="file.id" :customer-id="customerId" :show-delete="showDelete" :show-history="showHistory" @delete-file="deleteFile($event)"></file-item>
        <tr v-if="sortedFiles.length==0"><td class="text-muted" colspan="2">No Files Available.</td></tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import axios from 'axios';
  import apiRequest from '../../JS/api-request';
  import FileItem from './FileItem.vue';
  import { faSearch, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import { BSkeletonTable } from 'bootstrap-vue';
  import { orderBy, filter } from 'lodash';

  export default {
    name: 'FileList',
    components: { FileItem, BSkeletonTable },
    data: function () {
      return {
        files: [],
        isLoading: false,
        search: '',
        orderColumn: 'visitDate',
        orderUp: true,
        fileTypes: [],
        selectedFileType: ''
      }
    },
    mounted() {
      this.load();
      this.$root.$on('file-uploaded', this.load);
    },
    beforeDestroy() {
      this.$root.$off('file-uploaded', this.load);
    },
    watch: {
      siteId() {
        this.load();
      }
    },
    props: ['customerId', 'siteId', 'showDelete', 'showHistory'],
    computed: {
      orderDirection() {
        return this.orderUp ? 'up' : 'down';
      },
      sortedFiles() {
        var self = this;
        var lowerTerm = self.search.toLowerCase();
        return orderBy(filter(this.files, function (file) {
          return (lowerTerm === '' || file.name.toLowerCase().indexOf(lowerTerm) >= 0) && (self.selectedFileType === '' || self.selectedFileType === file.fileType)
        }), [self.orderColumn], [self.orderDirection === 'up' ? 'desc' : 'asc']);
      },
      searchIcon() {
        return faSearch;
      },
      orderIcon() {
        return this.orderUp ? faChevronUp : faChevronDown;
      }
    },
    methods: {
      async load() {
        var self = this;

        if (self.customerId && self.siteId) {
          self.isLoading = true;
          var url = `/api/customers/${self.customerId}/files`;

          if (self.siteId !== -1) {
            url = `/api/customers/${self.customerId}/sites/${self.siteId}/files`;
          }

          try {
            const response = await Promise.all([axios.get(url), axios.get('/api/filetypes')]);
            self.files = response[0].data;
            self.fileTypes = response[1].data;
            self.fileTypes.unshift({ id: '', name: 'All' });
          }
          finally {
            self.isLoading = false;
          }
        }
      },
      async deleteFile(file) {
        var self = this;
        const result = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this file?', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        });

        if (!result) {
          return;
        }

        await apiRequest({
          method: 'delete',
          url: `/api/customers/${self.customerId}/files/${file.name}?isOriginal=${file.isOriginal}`,
          busyFunc: x => this.isLoading = x,
          successFunc: x => {
            self.load();
          },
          errorMsg: 'There was an deleting file'
        });
      }
    }
  }
</script>
