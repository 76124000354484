var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "align-middle", staticStyle: { width: "100px" } }, [
      _c("small", { staticClass: "text-muted text-nowrap" }, [
        _vm._v(_vm._s(_vm._f("formatDate")(_vm.file.visitDate))),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "align-middle" },
      [
        _vm.file.sentEmail
          ? _c("font-awesome-icon", { attrs: { icon: _vm.emailIcon } })
          : _vm._e(),
        _vm._v(" "),
        _vm.file.fileType === "Report" && !_vm.file.isOriginal
          ? _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.viewReport(_vm.file)
                  },
                },
              },
              [
                _vm._v("\n      Site Visit Report\n      "),
                _c("font-awesome-icon", { attrs: { icon: _vm.viewIcon } }),
              ],
              1
            )
          : _c("file-download", {
              attrs: {
                filename: _vm.file.name,
                "is-original": _vm.file.isOriginal,
                "file-type": _vm.file.fileType,
                "customer-id": _vm.customerId,
              },
            }),
        _vm._v(" "),
        _vm.showHistory
          ? _c(
              "span",
              [
                _c("font-awesome-icon", {
                  attrs: { icon: _vm.infoIcon, id: "history-" + _vm.file.id },
                }),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: "history-" + _vm.file.id,
                      title: "History",
                      triggers: "hover",
                    },
                    on: { show: _vm.getHistory },
                  },
                  [
                    _vm.isLoading
                      ? _c("b-spinner", {
                          attrs: {
                            label: "Loading...",
                            variant: "primary",
                            size: "small",
                          },
                        })
                      : _vm.history.length > 0
                      ? _c(
                          "ul",
                          _vm._l(_vm.history, function (item) {
                            return _c("li", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm._f("formatDate")(item.auditDate)) +
                                  " - " +
                                  _vm._s(item.user) +
                                  " (" +
                                  _vm._s(item.action) +
                                  ")\n          "
                              ),
                            ])
                          }),
                          0
                        )
                      : _c("span", [_vm._v("No History")]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showDelete
      ? _c(
          "td",
          { staticClass: "align-middle", staticStyle: { width: "50px" } },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-link text-danger",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("delete-file", _vm.file)
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: _vm.deleteIcon } })],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }