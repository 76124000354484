<template>
  <b-form-group label="Activities" v-slot="{ ariaDescribedby }">
    <b-spinner label="Loading..." v-if="isLoading" />
    <b-form-checkbox-group v-else :value="localValue" @input="update($event)" :options="activities" :aria-describedby="ariaDescribedby" />
  </b-form-group>
</template>

<script>
  import apiRequest from '../../JS/api-request';
  import { BFormGroup, BFormCheckboxGroup, BSpinner } from 'bootstrap-vue';

  export default {
    name: 'AccountContact',
    components: { BFormGroup, BFormCheckboxGroup, BSpinner },
    data: function () {
      return {
        activities: null,
        isLoading: true
      };
    },
    props: ["customerId", "siteId", "value"],
    async mounted() {
      await this.load();
    },
    computed: {
      localValue() {
        return this.value ?? [];
      }
    },
    methods: {
      update(value) {
        this.$emit('input', [...value]);
      },
      async load() {
        await apiRequest({
          method: 'get',
          url: `/api/customers/${this.customerId}/sites/${this.siteId}/activities`,
          busyFunc: x => this.isLoading = x,
          successFunc: x => {
            this.activities = x.map(y => ({ text: y.title, value: y.activityId }));
          },
          errorMsg: 'There was an error fetching activity',
          toaster: this.$bvToast
        });
      }
    }
  }
</script>
