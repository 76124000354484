var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-group", {
    attrs: { label: "Activities" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var ariaDescribedby = ref.ariaDescribedby
          return [
            _vm.isLoading
              ? _c("b-spinner", { attrs: { label: "Loading..." } })
              : _c("b-form-checkbox-group", {
                  attrs: {
                    value: _vm.localValue,
                    options: _vm.activities,
                    "aria-describedby": ariaDescribedby,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.update($event)
                    },
                  },
                }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }