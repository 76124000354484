export default function init() {
  const toggles = document.querySelectorAll('[data-toggle]');

  toggles.forEach(toggle => {
    toggle.addEventListener('click', () => {
      const target = toggle.getAttribute('data-target');
      const toggleContent = document.querySelector(target);
      toggleContent.classList.toggle('collapse');
    });
  });
}
