import axios from 'axios';

export default async function apiRequest(context) {
  context.busyFunc(true);
  try {
    const { data } = await axios({
      method: context.method,
      url: context.url,
      data: context.data
    });

    if (context.successFunc) {
      context.successFunc(data);
    }

    if (context.successMsg && context.toaster) {
      context.toaster.toast(context.successMsg, {
        title: 'Profile',
        autoHideDelay: 5000,
        variant: 'success',
        appendToast: true
      });
    }
  } catch (ex) {
    let msg = context.errorMsg;
    console.log(ex);

    if (window.appInsights) {
      window.appInsights.trackException({ exception: ex });
    }

    if (ex.response && ex.response.status === 400) {
      msg += `- ${ex.response.data}`;
    }

    if (context.toaster) {
      context.toaster.toast(msg, {
        title: 'Error',
        autoHideDelay: 5000,
        variant: 'danger',
        appendToast: true
      });
    }
  } finally {
    context.busyFunc(false);
  }
}
