var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticStyle: { "word-break": "break-all" } }, [
    _vm.url == null
      ? _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.download()
              },
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(
                  !_vm.isOriginal && _vm.fileType === "Report"
                    ? "Site Visit Report"
                    : _vm.filename
                ) +
                "\n    "
            ),
            _vm.isLoading
              ? _c(
                  "span",
                  [
                    _c("b-spinner", {
                      attrs: {
                        label: "Loading...",
                        variant: "primary",
                        small: "",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: _vm.downloadIcon },
                    }),
                  ],
                  1
                ),
          ]
        )
      : _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
          _vm._v(
            "\n    " +
              _vm._s(
                !_vm.isOriginal && _vm.fileType === "Report"
                  ? "Site Visit Report"
                  : _vm.filename
              ) +
              "  \n    "
          ),
          _c(
            "span",
            [
              _c("font-awesome-icon", { attrs: { icon: _vm.fileIcon } }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [_vm._v("Open File")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }