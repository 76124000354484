var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { enctype: "multipart/form-data", id: "upload-form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitFile.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "input-group mb-3" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedFileType,
                  expression: "selectedFileType",
                },
              ],
              staticClass: "custom-select",
              attrs: { id: "inputGroupSelect01" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedFileType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.fileTypes, function (fileType) {
              return _c("option", { domProps: { value: fileType.id } }, [
                _vm._v(_vm._s(fileType.name)),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.selectedFileType !== "Report"
        ? _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "custom-file" }, [
              _c("input", {
                staticClass: "custom-file-input cursor-pointer",
                attrs: {
                  type: "file",
                  name: "uploadFile",
                  id: "dataFileInput",
                },
                on: {
                  change: function ($event) {
                    return _vm.fileInputChange($event.target.files)
                  },
                },
              }),
              _vm._v(" "),
              _vm.fileName != null
                ? _c(
                    "label",
                    {
                      staticClass: "custom-file-label",
                      attrs: { for: "dataFileInput" },
                    },
                    [_vm._v(_vm._s(_vm.fileName))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fileName == null
                ? _c(
                    "label",
                    {
                      staticClass: "custom-file-label",
                      attrs: { for: "dataFileInput" },
                    },
                    [_vm._v("Choose a file")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedFileType === "Report"
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "findings" } }, [
                    _vm._v("Report Date"),
                  ]),
                  _vm._v(" "),
                  _c("vue-date-picker", {
                    attrs: { placeholder: "Choose date", required: "" },
                    model: {
                      value: _vm.reportDate,
                      callback: function ($$v) {
                        _vm.reportDate = $$v
                      },
                      expression: "reportDate",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "findings" } }, [
                  _vm._v("Time on site (Minutes)"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timeOnSite,
                      expression: "timeOnSite",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number" },
                  domProps: { value: _vm.timeOnSite },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.timeOnSite = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "findings" } }, [
                    _vm._v("Revisit Date"),
                  ]),
                  _vm._v(" "),
                  _c("vue-date-picker", {
                    attrs: { placeholder: "Choose date", required: "" },
                    model: {
                      value: _vm.revisitDate,
                      callback: function ($$v) {
                        _vm.revisitDate = $$v
                      },
                      expression: "revisitDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("activity-select", {
                attrs: { "customer-id": _vm.customerId, "site-id": _vm.siteId },
                model: {
                  value: _vm.fileActivities,
                  callback: function ($$v) {
                    _vm.fileActivities = $$v
                  },
                  expression: "fileActivities",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("b-form-group", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var ariaDescribedby = ref.ariaDescribedby
                            return [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "btn-radios-2",
                                  options: _vm.reportOptions,
                                  "aria-describedby": ariaDescribedby,
                                  "button-variant": "outline-primary",
                                  name: "radio-btn-outline",
                                  buttons: "",
                                },
                                model: {
                                  value: _vm.visitType,
                                  callback: function ($$v) {
                                    _vm.visitType = $$v
                                  },
                                  expression: "visitType",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2492614024
                    ),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "isRodenticideUsed" } }, [
                  _vm._v("Will Rodenticide be used?"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isRodenticideUsed,
                      expression: "isRodenticideUsed",
                    },
                  ],
                  staticClass: "ml-2",
                  attrs: { type: "checkbox", id: "isRodenticideUsed" },
                  domProps: {
                    checked: Array.isArray(_vm.isRodenticideUsed)
                      ? _vm._i(_vm.isRodenticideUsed, null) > -1
                      : _vm.isRodenticideUsed,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isRodenticideUsed,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isRodenticideUsed = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isRodenticideUsed = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isRodenticideUsed = $$c
                      }
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "rodenticideUsed" } }, [
                  _vm._v("Rodenticide used"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rodenticideUsed,
                        expression: "rodenticideUsed",
                      },
                    ],
                    staticClass: "custom-select mb-2",
                    attrs: { id: "rodenticideUsed" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.rodenticideUsed = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(
                    _vm.reportData["rodenticideTypes"],
                    function (rodenticideType) {
                      return _c(
                        "option",
                        { domProps: { value: rodenticideType.value } },
                        [_vm._v(_vm._s(rodenticideType.key))]
                      )
                    }
                  ),
                  0
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rodenticideUsed2,
                        expression: "rodenticideUsed2",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: { id: "rodenticideUsed2" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.rodenticideUsed2 = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(
                    _vm.reportData["rodenticideTypes"],
                    function (rodenticideType) {
                      return _c(
                        "option",
                        { domProps: { value: rodenticideType.value } },
                        [_vm._v(_vm._s(rodenticideType.key))]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "infestationType" } }, [
                  _vm._v("Infestation Type"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.infestationType,
                        expression: "infestationType",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: { id: "infestationType" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.infestationType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.infTypes, function (infType) {
                    return _c("option", { domProps: { value: infType } }, [
                      _vm._v(_vm._s(infType)),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "population" } }, [
                  _vm._v("Population"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.population,
                        expression: "population",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: { id: "population" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.population = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.popTypes, function (popType) {
                    return _c("option", { domProps: { value: popType } }, [
                      _vm._v(_vm._s(popType)),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "isInsideOnly" } }, [
                  _vm._v("Bait Indoors Only"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isInsideOnly,
                      expression: "isInsideOnly",
                    },
                  ],
                  staticClass: "ml-2",
                  attrs: { type: "checkbox", id: "isInsideOnly" },
                  domProps: {
                    checked: Array.isArray(_vm.isInsideOnly)
                      ? _vm._i(_vm.isInsideOnly, null) > -1
                      : _vm.isInsideOnly,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isInsideOnly,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isInsideOnly = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isInsideOnly = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isInsideOnly = $$c
                      }
                    },
                  },
                }),
              ]),
              !_vm.isInsideOnly
                ? _c("div", [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "otherMethods" } }, [
                        _vm._v("Why can't other methods be used"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.otherSelect,
                              expression: "otherSelect",
                            },
                          ],
                          staticClass: "custom-select mb-2",
                          attrs: { id: "otherSelect" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.otherSelect = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                _vm.otherMethods = _vm.otherSelect
                              },
                            ],
                          },
                        },
                        _vm._l(
                          _vm.reportData["otherTypes"],
                          function (otherType) {
                            return _c(
                              "option",
                              { domProps: { value: otherType.value } },
                              [_vm._v(_vm._s(otherType.key))]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.otherMethods,
                            expression: "otherMethods",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "otherMethods",
                          rows: "3",
                          rows: _vm.rows(_vm.otherMethods),
                        },
                        domProps: { value: _vm.otherMethods },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.otherMethods = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "fgars" } }, [
                        _vm._v("Why is it intended to use FGARs/SGARs?"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.fgarSelect,
                              expression: "fgarSelect",
                            },
                          ],
                          staticClass: "custom-select mb-2",
                          attrs: { id: "fgarSelect" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.fgarSelect = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                _vm.fgars = _vm.fgarSelect
                              },
                            ],
                          },
                        },
                        _vm._l(
                          _vm.reportData["fgarTypes"],
                          function (fgarType) {
                            return _c(
                              "option",
                              { domProps: { value: fgarType.value } },
                              [_vm._v(_vm._s(fgarType.key))]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fgars,
                            expression: "fgars",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "fgars",
                          rows: "3",
                          rows: _vm.rows(_vm.fgars),
                        },
                        domProps: { value: _vm.fgars },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.fgars = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "anyChanges" } }, [
                        _vm._v("Any changes to the initial ERA?"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.anyChanges,
                            expression: "anyChanges",
                          },
                        ],
                        staticClass: "ml-2",
                        attrs: { type: "checkbox", id: "anyChanges" },
                        domProps: {
                          checked: Array.isArray(_vm.anyChanges)
                            ? _vm._i(_vm.anyChanges, null) > -1
                            : _vm.anyChanges,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.anyChanges,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.anyChanges = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.anyChanges = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.anyChanges = $$c
                            }
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "findings" } }, [
                  _vm._v("Findings and Treatments"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.findings,
                      expression: "findings",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "findings",
                    rows: _vm.rows(_vm.findings),
                    required: "",
                  },
                  domProps: { value: _vm.findings },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.findings = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "recommendations" } }, [
                  _vm._v("Recommendations"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.recommendations,
                      expression: "recommendations",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    rows: _vm.rows(_vm.recommendations),
                    id: "recommendations",
                    required: "",
                  },
                  domProps: { value: _vm.recommendations },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.recommendations = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "sendEmail" } }, [
                  _vm._v("Send Email"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sendEmail,
                      expression: "sendEmail",
                    },
                  ],
                  staticClass: "ml-2",
                  attrs: { type: "checkbox", id: "sendEmail" },
                  domProps: {
                    checked: Array.isArray(_vm.sendEmail)
                      ? _vm._i(_vm.sendEmail, null) > -1
                      : _vm.sendEmail,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.sendEmail,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.sendEmail = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.sendEmail = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.sendEmail = $$c
                      }
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "sendEmail" } }, [
                  _vm._v("Send Email to Main Contact"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sendEmailMain,
                      expression: "sendEmailMain",
                    },
                  ],
                  staticClass: "ml-2",
                  attrs: { type: "checkbox", id: "sendEmailMain" },
                  domProps: {
                    checked: Array.isArray(_vm.sendEmailMain)
                      ? _vm._i(_vm.sendEmailMain, null) > -1
                      : _vm.sendEmailMain,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.sendEmailMain,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.sendEmailMain = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.sendEmailMain = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.sendEmailMain = $$c
                      }
                    },
                  },
                }),
              ]),
              _vm.contacts && _vm.contacts.length > 0
                ? _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "email" } }, [
                      _vm._v("Report contact override"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contactId,
                            expression: "contactId",
                          },
                        ],
                        staticClass: "custom-select",
                        attrs: { id: "inputGroupSelect01" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.contactId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.contacts, function (contact) {
                        return _c(
                          "option",
                          { domProps: { value: contact.id } },
                          [_vm._v(_vm._s(contact.name))]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit", disabled: _vm.isUploading },
          },
          [
            _vm.isUploading
              ? _c("b-spinner", {
                  attrs: { label: "Loading...", variant: "light", small: "" },
                })
              : _vm._e(),
            _vm._v(" Upload"),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          attrs: { for: "inputGroupSelect01" },
        },
        [_vm._v("Type")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }